var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "divRef",
    staticClass: "bg-image d-flex-center flex-column",
    style: "\n    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(".concat(_vm.backgroundFlightSearchUrl, ");\n    min-height: ").concat(_vm.heightCard, ";\n  ")
  }, [_c('div', {
    staticClass: "search-flight-modal d-flex flex-column justify-content-start align-items-center mt-50"
  }, [_c('div', {
    staticClass: "w-100 d-flex-between"
  }, [_c('SearchSwitchTypeCheckbox', {
    attrs: {
      "type": _vm.searchData.type
    },
    on: {
      "update:type": function updateType($event) {
        return _vm.$set(_vm.searchData, "type", $event);
      }
    }
  }), _vm.isShowResetSearch ? _c('b-button', {
    staticClass: "p-50 mb-50",
    attrs: {
      "variant": "flat-dark"
    },
    on: {
      "click": _vm.handleResetSearch
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "RotateCwIcon",
      "size": "20"
    }
  })], 1) : _vm._e()], 1), _vm.searchData.type === 'OW' ? _c('oneWay', {
    attrs: {
      "search-data": _vm.searchData,
      "is-disabled-permitted-cariers": _vm.isDisabledPermittedCariers
    },
    on: {
      "update:searchData": function updateSearchData($event) {
        _vm.searchData = $event;
      },
      "update:search-data": function updateSearchData($event) {
        _vm.searchData = $event;
      },
      "swap-from-to": _vm.swapHandle
    }
  }) : _vm._e(), _vm.searchData.type === 'RT' ? _c('roundTrip', {
    attrs: {
      "search-data": _vm.searchData,
      "is-disabled-permitted-cariers": _vm.isDisabledPermittedCariers,
      "isDisabledGroupedItinerary": _vm.isDisabledGroupedItinerary
    },
    on: {
      "update:searchData": function updateSearchData($event) {
        _vm.searchData = $event;
      },
      "update:search-data": function updateSearchData($event) {
        _vm.searchData = $event;
      },
      "swap-from-to": _vm.swapHandle
    }
  }) : _vm._e(), _vm.searchData.type === 'MC' ? _c('multiCity', {
    attrs: {
      "search-data": _vm.searchData,
      "is-disabled-permitted-cariers": _vm.isDisabledPermittedCariers,
      "isDisabledGroupedItinerary": _vm.isDisabledGroupedItinerary
    },
    on: {
      "update:searchData": function updateSearchData($event) {
        _vm.searchData = $event;
      },
      "update:search-data": function updateSearchData($event) {
        _vm.searchData = $event;
      },
      "swap-from-to": _vm.swapHandle
    }
  }) : _vm._e(), _c('BRow', {
    staticClass: "w-100"
  }, [!_vm.isMobileView ? _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }) : _vm._e(), _c('BCol', {
    staticClass: "d-flex-center",
    attrs: {
      "cols": "12",
      "sm": "8",
      "md": "6"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      modifiers: {
        "400": true
      }
    }],
    staticStyle: {
      "background-color": "#1A5A7E !important"
    },
    attrs: {
      "pill": "",
      "disabled": !_vm.isActiveSearchButton
    },
    on: {
      "click": _vm.handleSearchButton
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('img', {
    staticClass: "mr-25 ico-bold",
    attrs: {
      "src": require("@icons/search.svg"),
      "alt": "Search"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.searchFlight')) + " ")])])], 1), _c('BCol', {
    staticClass: "d-flex-center mt-50 mt-sm-0",
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "3"
    }
  }, [_c('ServiceFeeCustomButton')], 1)], 1)], 1), _c('div', {
    staticClass: "search-flight-history my-1 p-1"
  }, [_c('div', {
    staticClass: "fw-700 text-center mb-50"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.historySearchFlight')) + " ")]), !_vm.isEmpty(_vm.flightsHistory) ? _c('div', {
    staticClass: "scroll-container d-flex overflow-auto gap-2",
    staticStyle: {
      "justify-content": "safe center"
    }
  }, _vm._l(_vm.flightsHistory, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "p-50 bg-white rounded-lg mb-25 border cursor-pointer hover-overlay",
      on: {
        "click": function click($event) {
          return _vm.handleApplyHistory(item);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex-between fw-800 text-nowrap mb-50"
    }, [_c('span', {
      staticClass: "mr-75"
    }, [_vm._v(" " + _vm._s(_vm.$t("flight.".concat(item.flightRequest.type))) + " ")]), _c('small', {
      staticClass: "fw-700 text-nowrap ml-75"
    }, [item.flightRequest.adult ? _c('span', {
      staticClass: "text-nowrap fw-800"
    }, [_c('span', {
      staticClass: "text-primary"
    }, [_vm._v(_vm._s(item.flightRequest.adult))]), _vm._v(" NL ")]) : _vm._e(), item.flightRequest.child ? _c('span', {
      staticClass: "text-nowrap fw-800"
    }, [_c('span', {
      staticClass: "text-primary"
    }, [_vm._v(_vm._s(item.flightRequest.child))]), _vm._v(" TE ")]) : _vm._e(), item.flightRequest.infant ? _c('span', {
      staticClass: "text-nowrap"
    }, [_c('span', {
      staticClass: "text-primary fw-800"
    }, [_vm._v(_vm._s(item.flightRequest.infant))]), _vm._v(" EB ")]) : _vm._e()])]), item.flightRequest.type === 'RT' ? [_c('div', {
      staticClass: "border-primary rounded mb-25 py-25 px-50"
    }, [_c('div', {
      staticClass: "d-flex-center gap-1"
    }, [_c('div', {
      class: "d-flex align-items-center text-primary"
    }, [_c('span', {
      staticClass: "fw-700 text-nowrap"
    }, [_vm._v(_vm._s(item.flightRequest.flights[0].startPoint) + "-" + _vm._s(item.flightRequest.flights[0].endPoint))])]), _c('div', {
      staticClass: "fw-700 text-nowrap"
    }, [_vm._v(" - " + _vm._s(_vm.convertISODateTime(item.flightRequest.flights[0].departDate).date) + " ")])])]), _c('div', {
      staticClass: "border-primary rounded mb-25 py-25 px-50"
    }, [_c('div', {
      staticClass: "d-flex-center gap-1"
    }, [_c('div', {
      class: "d-flex align-items-center text-primary"
    }, [_c('span', {
      staticClass: "fw-700 text-nowrap"
    }, [_vm._v(_vm._s(item.flightRequest.flights[0].endPoint) + "-" + _vm._s(item.flightRequest.flights[0].startPoint))])]), _c('div', {
      staticClass: "fw-700 text-nowrap"
    }, [_vm._v(" - " + _vm._s(_vm.convertISODateTime(item.flightRequest.flights[0].returnDate).date) + " ")])])])] : _vm._l(item.flightRequest.flights, function (flight, flightIndex) {
      return _c('div', {
        key: flightIndex,
        staticClass: "border-primary rounded mb-25 py-25 px-50"
      }, [flightIndex < 3 ? _c('div', {
        staticClass: "d-flex align-items-center gap-1"
      }, [item.flightRequest.flights.length > 1 ? _c('div', [_vm._v(" " + _vm._s(flightIndex + 1) + ": ")]) : _vm._e(), _c('div', {
        class: "d-flex align-items-center text-primary"
      }, [_c('span', {
        staticClass: "fw-700 text-nowrap"
      }, [_vm._v(_vm._s(flight.startPoint) + "-" + _vm._s(flight.endPoint))])]), _c('div', {
        staticClass: "fw-700 text-nowrap"
      }, [_vm._v(" - " + _vm._s(_vm.convertISODateTime(flight.departDate).date) + " ")]), item.flightRequest.type === 'RT' ? _c('span', {
        staticClass: "fw-700 text-nowrap "
      }, [_vm._v(" - " + _vm._s(_vm.convertISODateTime(flight.returnDate).date) + " ")]) : _vm._e()]) : flightIndex === 3 ? [_c('small', {
        staticClass: "pr-1 text-info fw-700"
      }, [_vm._v(" +" + _vm._s(item.flightRequest.flights.length - 3) + " " + _vm._s(_vm.$t('reservation.itinerary')) + "... ")])] : _vm._e()], 2);
    })], 2);
  }), 0) : _vm.loadingHistory ? _c('div', {
    staticClass: "d-flex-center my-50"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "info",
      "label": "Text Centered",
      "small": "",
      "tag": "span"
    }
  })], 1) : _c('div', {
    staticClass: "d-flex-center my-50 fw-700 bg-white rounded-lg py-75"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.cheapestFareNotFound')) + " ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }